import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AuthService } from 'app/_services/auth.service';
import { AlertifyService } from 'app/_services/alertify.service';
import { Md5 } from 'ts-md5/dist/md5';
import * as sha512 from 'js-sha512';

@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class Login2Component implements OnInit {
    loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private alertify: AlertifyService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            login: ['', [Validators.required]],
            password: ['', Validators.required]
        });

    }

    login($ev) {
        $ev.preventDefault();
        // tslint:disable-next-line: forin
        for (const c in this.loginForm.controls) {
            this.loginForm.controls[c].markAsTouched();
        }

      //  const hashPwd = Md5.hashStr((this.loginForm.get('password').value));

        const payload = {
            username: this.loginForm.get('login').value,
            password:  sha512.sha512(this.loginForm.get('password').value),
            intention: 'CLIENT'
        };


        if (this.loginForm.valid) {

            this.authService
                .getAuthenticate(payload)
                .subscribe((data: any) => {
                    localStorage.setItem('jwt', data.results.jwt);
                    localStorage.setItem('expire_in', data.results.expire_in);

                    let hashPwd = Md5.hashStr((this.loginForm.get('password').value));

                    const payload = {
                        username: this.loginForm.get('login').value,
                        password: hashPwd
                    };

                    this.alertify.setDuration(30);

                    window.location.href = '/dashboard';

                    localStorage.setItem('jwt', data.results.jwt);
                    localStorage.setItem('expire_in', data.results.expire_in);
                    localStorage.setItem('user', JSON.stringify(data.results.data));
                  //  localStorage.setItem('local', this.local);

/*
                    this.authService.login(payload, data.results.jwt).subscribe(
                        next => {
                            if (next) {
                                window.location.href = '/dashboard';
                            } else {
                                this.alertify.error('Votre compte est désactivé', true);
                            }
                        }, error => {
                            console.log(error);
                            this.alertify.error('Erreur d\'authentification', true);
                        }
                    ); */

                });
        }
    }
}
