import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { AlertifyService } from './alertify.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  jwtHelper = new JwtHelperService();
  constructor(
    private router: Router,
    private alertify: AlertifyService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('jwt');
    const local: string = localStorage.getItem('local');

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      request = request.clone({
        headers: request.headers.set('Authorization', token),
        params: request.params.append('local', local)
      });
    }

    console.log('Intercepting Request:', request);


    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
             
            for( let i=0 ; i < Object.values(err.error.messages.parameter).length ; i++ ){
              this.alertify.error(Object.values(err.error.messages.parameter)[i].toString());
          }
            if (err.status == 401) {
              this.alertify.error('Session expiré!');
              // not logged in so redirect to login page with the return url
              this.router.navigate(['/login']);
              return;
            }

            return Observable.throw(err.message);
          }
        }
      })
    );

    //   return next.handle(request)
    //     .catch((err: HttpErrorResponse) => {

    //       if (err.error instanceof Error) {
    //         // A client-side or network error occurred. Handle it accordingly.
    //         console.error('An error occurred:', err.error.message);
    //       } else {
    //         // The backend returned an unsuccessful response code.
    //         // The response body may contain clues as to what went wrong,
    //         console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
    //         if (err.status == 401) {
    //           this.alertify.error('Session expiré!');
    //           // not logged in so redirect to login page with the return url
    //           this.router.navigate(['/login']);
    //           return;
    //         }

    //         return Observable.throw(err.message);
    //       }

    //       // ...optionally return a default fallback value so app can continue (pick one)
    //       // which could be a default value
    //       return Observable.of(new HttpResponse({
    //         body: [
    //           { name: "Default values returned by Interceptor", id: 88 },
    //           { name: "Default values returned by Interceptor(2)", id: 89 }
    //         ]
    //       }));

    //       // or simply an empty observable
    //       // return Observable.empty<HttpEvent<any>>();
    //     });
  }
}
