// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
      apiUrl: 'https://devapi.taxiora.tn/',
   // apiUrl: 'http://api.taxiora.local/',
    token: 'e409219c8e64417089a2d4224db66745',
    firebase: {
        apiKey: 'AIzaSyB3LNDXM3ZCArquQgdUMU6aqBwE0jgF11g',
        authDomain: 'taxiora-48d5e.firebaseapp.com',
        databaseURL: 'https://taxiora-48d5e.firebaseio.com',
        projectId: 'taxiora-48d5e',
        storageBucket: 'taxiora-48d5e.appspot.com',
        messagingSenderId: '389930730636',
        appId: '1:389930730636:web:112f42f2ef76b8ae1967a9',
        measurementId: 'G-WZLV9WLPRL'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
