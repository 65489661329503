import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "environments/environment";
import { User } from "app/features/users/models/user.model";

const localUser = JSON.parse(localStorage.getItem("user"));
@Injectable({
    providedIn: "root",
})
export class TripsService {
    private userUrl = `${environment.apiUrl}front/user/${localUser?.code}/trips`;

    constructor(private http: HttpClient) { }

    getTrips(params): Observable<any> {
        return this.http.get<any>(`${this.userUrl}`, { params });
    }
}
