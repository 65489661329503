import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "environments/environment";

const localUser = JSON.parse(localStorage.getItem("user"));
@Injectable({
    providedIn: "root",
})
export class CarnetService {
    private interventionUrl = `${environment.apiUrl}front/client/${localUser?.client_code}/taxi/${localUser?.tx_code}/interventions`;
    private repairsUrl = `${environment.apiUrl}front/client/${localUser?.client_code}`;
    private carnetUrl = `${environment.apiUrl}front/client/${localUser?.client_code}/taxi/${localUser?.tx_code}/carnet`;

    constructor(private http: HttpClient) { }

    getInterventions(params: any): Observable<any> {
        return this.http.get<any>(`${this.interventionUrl}`, params);
    }

    getRepairs(codeIntervention: string): Observable<any> {
        return this.http.get<any>(`${this.repairsUrl}/intervention/${codeIntervention}/repairs`);
    }

    getCarnet(): Observable<any> {
        return this.http.get<any>(`${this.carnetUrl}`);
    }

}
