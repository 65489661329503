import { Trip } from 'app/features/trips/models/trip.model';


export class GetTrips {
    static readonly type = '[Trips] Get';

    constructor(public payload: any) {}
}

export class UpdateSelectedTrip {
    static readonly type = '[Trip] Update Selected Trip';

    constructor(public trip: Trip ) {}
}

