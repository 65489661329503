export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': 'Dashboard',
            'GROUPS': 'Groups',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'USERS' : {
                'TITLE': 'My drivers',
                'ACCOUNT': 'My Account',
                'DRIVERS': 'The drivers',
                "DRIVER_LIST" :'List of drivers' ,
                "DRIVER_MANAGMENT":'Driver Management'
            },
            'TRIPS'        : {
                'TITLE': 'Trips',
                'RECEPES': 'Receipt',
                'RESERVATIONS':'Reservations'
            },
            'REPAIR'        : {
                'TITLE': 'My repair tools',
                'METROLOG': 'Metrological log'
            }
        }
    }
};
