

export class GetInterventions {
    static readonly type = '[Carnet] Get Interventions';

    constructor( public payload: any) {}
}

export class GetRepairs {
    static readonly type = '[Carnet] Get Repairs';

    constructor(public codeIntervention: string) {}
}

export class GetCarnet {
    static readonly type = '[Carnet] Get Carnet';

    constructor() {}
}


