import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        translate: "NAV.DASHBOARD",
      //  type: 'group',
       // children: [
       //     {
             //   id: "dashboard",
             //   title: "Dashboard",
             //   translate: "NAV.DASHBOARD",
                type: 'item',
                icon: "home",
                url: "/dashboard"
           // }
       // ]
    }, {
        id: "users",
        title: "Chauffeurs",
        translate: "NAV.USERS.TITLE",
        // type: 'group',
        // children: [
        //     {
             //   id: "users",
             //   title: "Utilisateurs",
           //     translate: "NAV.USERS.TITLE",
                type: "item",
                icon: "group",
                url: "/users",
          //  },
            // {
            //     id: "profile",
            //     title: "Mon compte",
            //     translate: "NAV.USERS.ACCOUNT",
            //     type: "item",
            //     icon: "persone",
            //     url: "/profile",

            // },
       // ]
    },
    {
        id: "trips",
        title: "Courses",
        translate: "NAV.TRIPS.TITLE",
        type: 'collapsable',
        icon: "list",
        children: [
            {
                id: "trip",
                title: "Courses",
                translate: "NAV.TRIPS.TITLE",
                type: "item",
                icon: "local_taxi",
                url: "/trips",
            },
            {
                id: "recipes",
                title: "Recette",
                translate: "NAV.TRIPS.RECEPES",
                type: "item",
                icon: "receipt",
                url: "/recipes",
            },
            {
                id: "bookings",
                title: "Réservations",
                translate: "NAV.TRIPS.RESERVATIONS",
                type: "item",
                icon: "commute",
                url: "/bookings",
            }
        ],
    },
    {
        id: "carnet",
        title: "Réparation",
        translate: "NAV.REPAIR.TITLE",
        type: 'collapsable',
        icon: "build",
        children: [
            {
                id: "carnet",
                title: "Carnet métrologique ",
                translate: "NAV.REPAIR.METROLOG",
                type: "item",
                icon: "rate_review",
                url: "/carnet",
            }
        ]
    }
];
