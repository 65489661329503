import { User } from 'app/features/users/models/user.model';


export class GetUsers {
    static readonly type = '[Users] Get';

    constructor(public payload: any) {}
}

export class UpdateSelectedUser {
    static readonly type = '[User] Update Selected User';

    constructor(public user: User ) {}
}


export class GetUserById {
    static readonly type = '[User] Get  User By Id';

    constructor(public user: User ) {}
}
