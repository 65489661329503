import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "environments/environment";

const localUser = JSON.parse(localStorage.getItem("user"));
@Injectable({
    providedIn: "root",
})
export class RecipesService{
    private userUrl = `${environment.apiUrl}front/user/${localUser?.code}/recipes`;
    constructor(private http: HttpClient) { }

    getRecipes(params): Observable<any> {
        return this.http.get<any>(`${this.userUrl}`, {params});
    }

    getInvoice(codeInvoice: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}front/user/${localUser?.code}/invoice/${codeInvoice}`);
    }
}
