import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "environments/environment";
import { User } from "app/features/users/models/user.model";
// const httpOptions = {
//     headers: new HttpHeaders({
//         Authorization: localStorage.getItem("jwt"),
//     }),
//     params: new HttpParams()
// };

const localUser = JSON.parse(localStorage.getItem("user"));
@Injectable({
    providedIn: "root",
})
export class BookingsService {
    private userUrl = `${environment.apiUrl}front/user/${localUser?.code}/bookings`;

    constructor(private http: HttpClient) { }

    getTrips(params): Observable<any> {
        // httpOptions.params = parameters;
        return this.http.get<any>(`${this.userUrl}`, { params });
    }

}
