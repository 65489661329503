import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import { User } from 'app/features/users/models/user.model';
import { GetTrips, UpdateSelectedTrip } from './trips.action';
import { Trip } from 'app/features/trips/models/trip.model';
import { TripsService } from 'app/features/trips/services/trips.services';
import { TripStateModel } from './trips-state.model';

@State<TripStateModel>({
    name: 'trips',
    defaults: {
        trips: [],
        filters: undefined,
        pageSize: undefined,
        pageNumber: undefined,
        total: undefined
    }
})
@Injectable()
export class TripState {
    constructor(private tripService: TripsService) {
    }

    @Selector()
    static getPagination(state: TripStateModel) {
        return state.pageNumber;
    }

    @Selector()
    static getTotalCount(state: TripStateModel) {
        return state.total;
    }

    @Selector()
    static getTripList(state: TripStateModel) {
        return state.trips;
    }

    @Selector()
    static getSelectedUser(state: TripStateModel) {
        return state.selected;
    }

    @Action(GetTrips)
    GetTrips({ getState, setState }: StateContext<TripStateModel>, { payload }: GetTrips) {
        return this.tripService.getTrips(payload).pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            data.rows = data.rows.map(trip => {
                if (trip.completed)
                    trip.completed = '<span class=" badge nav-link-badge green-500"> Terminé </span>';
                else
                    trip.completed = '<span class=" badge nav-link-badge deep-orange-300"> Non terminé </span>';
                return trip;
            });

            setState({
                ...state,
                trips: data.rows as Trip[],
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.total,
                filters: {}
            });
        }));
    }

    @Action(UpdateSelectedTrip)
    UpdateSelectedUser({ getState, patchState }: StateContext<TripStateModel>, { trip }: UpdateSelectedTrip) {
        const state = getState();

        patchState({
            ...state,
            selected: trip as Trip
        });
    }
}
