import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'environments/environment';
import { User } from 'app/features/users/models/user.model';

const localUser = JSON.parse(localStorage.getItem('user'));
@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private userUrl = `${environment.apiUrl}front/client/${localUser?.client_code}/users`;

    constructor(private http: HttpClient) { }

    getUsers(params): Observable<any> {
        return this.http.get<any>(`${this.userUrl}`, { params });
    }

    getUsersById(id): Observable<any> {
        console.log(id);
        
        return this.http.get<any>(`${this.userUrl}/${id}`,);
    }

    addUser(user: User): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}front/client/${localUser?.client_code}/user`, user);
    }

    editUser(user, code: string): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}front/client/${localUser?.client_code}/user/${code}`, user);
    }

    editEnableUser(code: string): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}front/client/${localUser?.client_code}/user/${code}`, null);
    }

    editAvatarUser(code: string, avatar: string): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}front/user/${code}/avatar`, { avatar });
    }

    editPhoneUser(code: string, phone: string): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}front/user/${code}/phone`, { phone });
    }

    sendCodeSMS(code: string, codeSMS: string): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}front/user/${code}/phone`, { codeSms: codeSMS });
    }
}
