import { Recipe } from 'app/features/recipes/model/recipe.model';

export class GetRecipes {
    static readonly type = '[Recipes] Get';

    constructor(public payload: any) {}
}

export class UpdateSelectedRecipe {
    static readonly type = '[Recipe] Update Selected Recipe';

    constructor(public recipe: Recipe ) {}
}