import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import { BookingStateModel } from './bookings-state.model';
import { GetBookings } from './bookings.action';
import { Booking } from 'app/features/bookings/models/booking.model';
import { BookingsService } from 'app/features/bookings/services/bookings.services';


@State<BookingStateModel>({
    name: 'bookings',
    defaults: {
        bookings: [],
        // filters: undefined,
        pageSize: undefined,
        pageNumber: undefined,
        total: undefined
    }
})
@Injectable()
export class BookingState {
    constructor(private bookingService: BookingsService) {
    }

    @Selector()
    static getPagination(state: BookingStateModel) {
        return state.pageNumber;
    }

    @Selector()
    static getTotalCount(state: BookingStateModel) {
        return state.total;
    }

    @Selector()
    static getBookingList(state: BookingStateModel) {
        return state.bookings;
    }


    @Action(GetBookings)
    GetTrips({ getState, setState }: StateContext<BookingStateModel>, { payload }: GetBookings) {
        return this.bookingService.getTrips(payload).pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            // data.rows = data.rows.map(trip => {
            //     if (trip.completed)
            //         trip.completed = '<span class=" badge nav-link-badge green-500"> Terminé </span>';
            //     else
            //         trip.completed = '<span class=" badge nav-link-badge deep-orange-300"> Non terminé </span>';
            //     return trip;
            // });

            setState({
                ...state,
                bookings: data.rows as Booking[],
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.total,
                // filters: {}
            });
        }));
    }

}
