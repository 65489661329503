<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/logo_final_taxiora.svg">
                </div>
            </div>
            <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
            <button mat-button fxHide fxShow.gt-xs class="language-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">Taxi: &nbsp; {{user?.client?.registry}}</span>
                </div>
            </button>
            <div class="toolbar-separator"></div>
            <button mat-button fxHide fxShow.gt-xs class="language-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">Voiture: &nbsp; {{user?.client?.pro_card}}</span>
                </div>
            </button>
            <div class="toolbar-separator"></div>
            <button mat-button fxHide fxShow.gt-xs class="language-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">Sociéte: &nbsp;{{user?.client?.company}}</span>
                </div>
            </button>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar" [src]="user?.image?user.image:'assets/images/avatars/profile.jpg'"
                        [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" />
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user?.firstname}} {{user?.lastname}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item [routerLink]="'/profile'">
                    <mat-icon>account_circle</mat-icon>
                    <span>Mon compte</span>
                </button>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Se déconnecter</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="notifMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="secondary-text">add_alert</mat-icon>
                </div>
            </button>

            <mat-menu #languageMenu="notifMenu" [overlapTrigger]="false">

            </mat-menu> -->

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>
