import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import { RecipeStateModel } from './recipes-state.model';
import { GetRecipes, UpdateSelectedRecipe } from './recipes.action';
import { RecipesService } from 'app/features/recipes/services/recipes.service';
import { Recipe } from 'app/features/recipes/model/recipe.model';

@State<RecipeStateModel>({
    name: 'recipes',
    defaults: {
        invoices: [],
        totals: [],
        chart: [],
        highcharts: [],
        filters: undefined,
        pageSize: undefined,
        pageNumber: undefined,
        total: undefined
    }
})
@Injectable()
export class RecipeState {
    constructor(private recipeService: RecipesService) {
    }

    @Selector()
    static getPagination(state: RecipeStateModel) {
        return state.pageNumber;
    }

    @Selector()
    static getTotalCount(state: RecipeStateModel) {
        return state.total;
    }

    @Selector()
    static getRecipeList(state: RecipeStateModel) {
        return state.invoices;
    }

    @Selector()
    static getRecipeTotals(state: RecipeStateModel) {
        return state.totals;
    }

    @Selector()
    static getRecipeChart(state: RecipeStateModel) {
        return state.chart;
    }
    @Selector()
    static getRecipeHighcharts(state: RecipeStateModel) {
        return state.highcharts;
    }

    @Selector()
    static getSelectedUser(state: RecipeStateModel) {
        return state.selected;
    }

    @Action(GetRecipes)
    GetRecipes({ getState, setState }: StateContext<RecipeStateModel>, { payload }: GetRecipes) {
        return this.recipeService.getRecipes(payload).pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            setState({
                ...state,
                invoices: data.invoices as Recipe[],
                totals: data.totals,
                chart: data.chart,
                highcharts: data.highcharts,
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.invoices.length,
                filters: {}
            });
        }));
    }

    @Action(UpdateSelectedRecipe)
    UpdateSelectedUser({ getState, patchState }: StateContext<RecipeStateModel>, { recipe }: UpdateSelectedRecipe) {
        const state = getState();

        patchState({
            ...state,
            selected: recipe as Recipe
        });
    }
}
