import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import { CarnetStateModel } from './carnet-state.model';
import { CarnetService } from 'app/features/carnet/services/carnet.services';
import { GetInterventions, GetRepairs, GetCarnet } from './carnet.action';
import { Intervention } from 'app/features/carnet/models/intervention.model';
import { Repair } from 'app/features/carnet/models/repair.model';

@State<CarnetStateModel>({
    name: 'carnet',
    defaults: {
        interventions: [],
        repairs: [],
        carnet: [],
        filters: undefined,
        pageSize: undefined,
        pageNumber: undefined,
        total: undefined
    }
})
@Injectable()
export class CarnetState {
    constructor(private carnetService: CarnetService) {
    }

    @Selector()
    static getPagination(state: CarnetStateModel) {
        return state.pageNumber;
    }

    @Selector()
    static getTotalCount(state: CarnetStateModel) {
        return state.total;
    }

    @Selector()
    static getInterventionList(state: CarnetStateModel) {
        return state.interventions;
    }

    @Selector()
    static getRepairs(state: CarnetStateModel) {
        return state.repairs;
    }

    @Selector()
    static getCarnet(state: CarnetStateModel) {
        return state.carnet;
    }

    @Selector()
    static getSelectedUser(state: CarnetStateModel) {
        return state.selected;
    }

    @Action(GetInterventions)
    GetInterventions({ getState, setState }: StateContext<CarnetStateModel>, { payload }: GetInterventions) {
        return this.carnetService.getInterventions(payload).pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            setState({
                ...state,
                interventions: data.rows as Intervention[],
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.total,
                filters: {}
            });
        }));
    }

    @Action(GetRepairs)
    GetRepairs({ getState, setState }: StateContext<CarnetStateModel>, { codeIntervention }: GetRepairs) {
        return this.carnetService.getRepairs(codeIntervention).pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            setState({
                ...state,
                repairs: data.rows as Repair[],
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.total,
                filters: {}
            });
        }));
    }

    @Action(GetCarnet)
    GetCarnet({ getState, setState }: StateContext<CarnetStateModel>, { }: GetCarnet) {
        return this.carnetService.getCarnet().pipe(tap((result) => {
            const state = getState();
            let data = result.results.data;

            setState({
                ...state,
                carnet: data
            });
        }));
    }
}
