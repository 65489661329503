import { AlertifyService } from './../_services/alertify.service';
import { AuthService } from './../_services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

const localUser = JSON.parse(localStorage.getItem('user'));

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
    private authService: AuthService,
    private router: Router,
    private alertify: AlertifyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.loggedIn()) {

      // check if route is restricted by role
      // if (route.data.roles && route.data.roles.indexOf(localUser.status) === -1) {
      //   // role not authorised so redirect to home page
      //   this.router.navigate(['/']);
      //   return false;
      // }

      // const path = route.pathFromRoot.map(v => v.url.map(segment => segment.toString())).join('');


      // authorised so return true
      return true;
    }

    this.alertify.error('Vous n\'avez pas accès à cette ressource!!');
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
