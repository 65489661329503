import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { alertify.set('notifier', 'delay', 5); }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm(message, (e) => {
      if (e) {
        okCallback();
        this.warning('le projet a été enlevé de votre chrono');
      } else { }
    }).setHeader('<em> Taxiora </em> ');
  }

  notify(message: string) {
    // alertify.alert('MyApp', message);
    alertify.notify(message, 'custom', 40, function(){console.log('dismissed');});
  }

  success(message: string) {
    alertify.success(message);
  }

  setDuration(delay) {
    alertify.set('notifier', 'delay', delay);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  error(message: string, dismissOthers: boolean = false) {
    if (dismissOthers) {
      alertify.error(message).dismissOthers();
      return;
    }

    alertify.error(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
