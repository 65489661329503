import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import { UserStateModel } from './users-state.model';
import { User } from 'app/features/users/models/user.model';
import { UsersService } from 'app/features/users/services/users.services';
import { GetUserById, GetUsers, UpdateSelectedUser } from './users.action';

@State<UserStateModel>({
    name: 'users',
    defaults: {
        users: [],
        user: undefined,
        filters: undefined,
        pageSize: undefined,
        pageNumber: undefined,
        total: undefined
    }
})
@Injectable()
export class UserState {
    constructor(private userService: UsersService) {
    }

    @Selector()
    static getPagination(state: UserStateModel) {
        return state.pageNumber;
    }

    @Selector()
    static getTotalCount(state: UserStateModel) {
        return state.total;
    }

    @Selector()
    static getUserList(state: UserStateModel) {
        return state.users;
    }

    @Selector()
    static getSelectedUser(state: UserStateModel) {
        return state.selected;
    }


    @Selector()
    static getUserById(state: UserStateModel) {
        return state.selected;
    }
    @Action(GetUsers)
    GetUsers({ getState, setState }: StateContext<UserStateModel>, { payload }: GetUsers) {
        return this.userService.getUsers(payload).pipe(tap((result) => {
            const state = getState();
            const data = result.results.data;

            setState({
                ...state,
                users: data.rows as User[],
                pageNumber: data.current,
                pageSize: data.max_per_page,
                total: data.total,
                filters: {}
            });
        }));
    }


    @Action(GetUserById)
    GetUserById({ getState, setState }: StateContext<UserStateModel>, { payload }: GetUsers) {
        return this.userService.getUsersById(payload).pipe(tap((result) => {
            const state = getState();
            const data = result.results.data;

            setState({
                ...state,
                user: data as User,
             
                
            });
        }));
    }

    @Action(UpdateSelectedUser)
    UpdateSelectedUser({ getState, patchState }: StateContext<UserStateModel>, { user }: UpdateSelectedUser) {
        const state = getState();

        patchState({
            ...state,
            selected: user as User
        });
    }
}
