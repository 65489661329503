<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="center">

    <!-- <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/logo_taxiora.png">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Taxiora
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Taxiora est une plateforme qui propose des solutions pour une compteur de
            taxi connecté.
        </div>

    </div> -->

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
                <img src="assets/images/logos/logo_final_taxiora.svg">
            </div>

            <div class="title text-center">CONNECTEZ-VOUS À VOTRE COMPTE</div>

            <form name="loginForm" [formGroup]="loginForm" (submit)="login($event)" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Login</mat-label>
                    <input matInput formControlName="login" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('login').hasError('required') &&
                        (loginForm.controls['login'].dirty || loginForm.controls['login'].touched)">
                        Login est obligatoire
                    </mat-error>
                    <!-- <mat-error
                        *ngIf=" !loginForm.get('login').hasError('required') && loginForm.get('login').hasError('login') " >
                        Ce champ doit être une adresse e-mail valide
                    </mat-error> -->
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Mot de passe</mat-label>
                    <input matInput type="password" formControlName="password" />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="loginForm.get('password').hasError('required') &&
                    (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
                        Mot de passe est obligatoire
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN">
                    Se connecter
                </button>
            </form>

        </div>
    </div>
</div>
