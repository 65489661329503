
import { RouterState } from '@ngxs/router-plugin';
import { UserState } from './users/users.state';
import { TripState } from './trips/trips.state';
import { RecipeState } from './recipes/recipes.state';
import { BookingState } from './bookings/bookings.state';
import { CarnetState } from './carnet/carnet.state';

export * from './router';
export * from './users';
export * from './trips';
export * from './recipes';
export * from './bookings';
export * from './carnet';

/**
 * Import all State here
 */
export const AppState = [
    RouterState,
    UserState,
    TripState,
    RecipeState,
    BookingState,
    CarnetState
];
