export const locale = {
    lang: 'fr',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': 'Tableau de Bord',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'USERS'        : {
                'TITLE': 'Mes chauffeurs',
                'ACCOUNT': 'Mon compte',
                'DRIVERS': 'Les chauffeurs',
                "DRIVER_LIST" :'liste des chauffeurs' ,
                "DRIVER_MANAGMENT":'Gestion de chauffeurs'
            },
            'TRIPS'        : {
                'TITLE': 'Courses',
                'RECEPES': 'Recette',
                'RESERVATIONS':'Réservations'
            },
            'REPAIR'        : {
                'TITLE': 'Mes outils de reparation',
                'METROLOG': 'Carnet métrologique'
            }
        }
    }
};
