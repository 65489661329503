<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="center center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.gt-xs>
            <span>&copy; 2020 - Taxiora - v1.0.0</span>
            
        </div>

    </div>

</mat-toolbar>
