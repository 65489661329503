
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, retry} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = environment.apiUrl + 'authenticate';
  private loginUrl = environment.apiUrl + 'front/login';
  // private body = { token: environment.token };
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  local: string;

  constructor(
    private http: HttpClient,
    private _translateService: TranslateService) { }

  login(model: any, jwt: string) {
    this.local =  this._translateService.currentLang;
    
    return this.http.post(this.loginUrl, model)
      .pipe(
        map((response: any) => {
          if (response.results && response.results.data) {
            const user = response.results.data;
            if (user.active) {
              localStorage.setItem('jwt', response.results.jwt);
              localStorage.setItem('expire_in', response.results.expire_in);
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('local', this.local);
              return true;
            }
          }
          return false;
        })
      );
  }

    public getAuthenticate(body): Observable<any> {
        // return this.http.post<any>(this.authUrl, body);
        return this.http.post<any>(this.authUrl, body).pipe(retry(1), catchError(this.handleError));

    }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


    loggedIn() {
        const token = localStorage.getItem('jwt');
        const user = localStorage.getItem('user');
        return user && !this.jwtHelper.isTokenExpired(token);
    }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
    localStorage.removeItem('expire_in');
    localStorage.removeItem('local');
  }
}
